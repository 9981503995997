<template>
  <div class="d-flex flex-column">
    <div
      class="d-flex align-space-between align-md-center justify-space-between flex-column flex-md-row mb-8"
    >
      <v-row class="mb-8 mb-md-0" no-gutters>
        <v-col cols="12" sm="10" md="8" lg="7" xl="6">
          <custom-input
            v-model="search"
            placeholder="Realizar busca"
            appendIcon="mdi-magnify"
            :required="false"
            :hideDetails="true"
            @keyup="searchTeam()"
          />
        </v-col>
      </v-row>

      <v-spacer />

      <section class="d-flex justify-end">
        <switch-show-type @change="searchTeam()" />
      </section>
    </div>

    <loader-content v-if="loading" />

    <card-grid
      v-else-if="$store.getters.viewType === 'grid'"
      :items="search ? filteredTeams : _teams"
      @submit="openTeam($event)"
    />

    <!-- list mode -->
    <custom-table
      v-else
      :headers="headers"
      :items="search ? filteredTeams : league.teams"
    >
      <template v-slot:[`item.team`]="{ item }">
        <v-list-item class="px-0">
          <v-list-item-avatar v-if="$vuetify.breakpoint.smAndUp" tile>
            <v-img
              :src="item.logo || require('@/assets/league/empty-logo.png')"
            />
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>
              <router-link
                class="overtext--text"
                :to="`/league/team-details/${item.id}`"
              >
                {{ item.name }}
              </router-link>
            </v-list-item-title>
          </v-list-item-content>

          <v-list-item-avatar v-if="$vuetify.breakpoint.xsOnly" tile>
            <v-img
              :src="item.logo || require('@/assets/league/empty-logo.png')"
            />
          </v-list-item-avatar>
        </v-list-item>
      </template>

      <template v-slot:[`item.participants`]="{ item }">
        <v-tooltip color="black" bottom>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              {{ item.team_players.length }} / {{ league.x }}
            </div>
          </template>

          <div class="d-flex flex-column">
            <span v-for="(subitem, i) in item.team_players" :key="i">
              {{ subitem.user.nickname }}
            </span>
          </div>
        </v-tooltip>
      </template>

      <template v-slot:[`item.createdAt`]="{ item }">
        {{ formatDate(item.createdAt) }}
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <v-chip class="red" :color="getStatus(item).color" label>
          <span class="text--body-3 px-2" v-text="getStatus(item).name" />
        </v-chip>
      </template>
    </custom-table>
  </div>
</template>

<script>
import { formatDate } from "@/utils";

export default {
  data() {
    return {
      loading: false,
      interval: null,
      search: "",
      headers: [
        {
          text: "Equipe",
          value: "team",
        },
        {
          text: "Participantes",
          value: "participants",
        },
        {
          text: "Inscrito em",
          value: "createdAt",
        },
        {
          text: "Status",
          value: "status",
        },
      ],
      filteredTeams: [],
    };
  },

  props: {
    league: {
      type: null,
      required: true,
    },
  },

  computed: {
    _teams() {
      return this.league.teams.map((e) => {
        return {
          id: e.id,
          image: e.logo || require("@/assets/league/empty-logo.png"),
          title: e.name,
          subtitle: `[${e.tag || e.name}]`,
        };
      });
    },
  },

  methods: {
    openTeam(event) {
      this.$router.push({ path: `/league/team-details/${event}` });
    },

    searchTeam() {
      if (this.interval) clearInterval(this.interval);

      if (this.search) {
        this.loading = true;

        this.interval = setTimeout(() => {
          if (this.$store.getters.viewType === "grid") {
            this.filteredTeams = this._teams.filter((e) => {
              return e.title.toLowerCase().includes(this.search.toLowerCase());
            });
          } else {
            this.filteredTeams = this.league.teams.filter((e) => {
              return e.name.toLowerCase().includes(this.search.toLowerCase());
            });
          }

          this.loading = false;
        }, 300);
      } else {
        this.filteredTeams = [];
        this.loading = false;
      }
    },

    getStatus(item) {
      const x = this.league.x;
      const players = item.team_players.length;

      if (players == x) return { name: "Completo", color: "green" };
      if (players < x) return { name: "Incompleto", color: "grey" };
      if (players > x) return { name: "Excesso", color: "red" };

      return { name: "...", color: "grey" };
    },

    formatDate,
  },
};
</script>

<style></style>
